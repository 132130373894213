<template>
  <div class="add-user2">
    <el-form 
      v-if="form.id==''" 
      label-width="80px"
      label-position="right"
      :model="form" 
      status-icon 
      :rules="rules" 
      ref="form1"
      style="width:100%;">
      <el-form-item class="label" label="用户名" prop="uname">
        <el-input style="width:200px;" v-model="form.uname"></el-input>
      </el-form-item>
      <el-form-item class="label" label="姓名" prop="name">
        <el-input style="width:200px;" v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item class="label" label="密码" prop="pwd">
        <el-input style="width:200px;" placeholder="请输入密码" v-model="form.pwd" show-password autoComplete="off"></el-input>
      </el-form-item>
      <el-form-item class="label" label="确认密码" prop="repwd">
        <el-input style="width:200px;" placeholder="请输入确认密码" v-model="form.repwd" show-password autoComplete="off"></el-input>
      </el-form-item>
      <el-form-item class="label" label="性别">
        <el-radio-group v-model="form.sex">
          <el-radio :label="1">男</el-radio>
          <el-radio :label="0">女</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="label" label="出生日期" prop="birth">
        <el-date-picker
          type="date"
          placeholder="选择日期"
          v-model="form.birth"
          format="yyyy 年 MM 月 dd 日"
          value-format="timestamp"
           style="width:200px;"
        ></el-date-picker>
      </el-form-item>
      <el-form-item class="label" label="籍贯" prop="rid">
          <el-select class="select"
            v-model="form.pid"
            placeholder="请选择省份"
            @change="getCitiesByPid"
          >
            <el-option
              v-for="item in provs"
              :key="item.id"
              :label="item.t_cityname"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select
           class="select"
            v-model="form.cid"
            placeholder="请选择市"
            @change="getRegionsByCid"
          >
            <el-option
              v-for="item in cities"
              :key="item.id"
              :label="item.t_cityname"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-select 
            v-model="form.rid"
             placeholder="请选择区/县"
           class="select">
            <el-option
              v-for="item in regions"
              :key="item.id"
              :label="item.t_cityname"
              :value="item.id"
            >
            </el-option>
          </el-select>
      </el-form-item>
      <el-form-item class="label" label="兴趣爱好">
        <el-checkbox-group v-model="form.iids">
          <el-checkbox class="chb" v-for="item in inters" :label="item.id" :key="item.id"><span style="font-size:12px" class="label">{{
            item.interest
          }}</span></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item class="label" label="自我介绍">
        <el-input
          type="textarea"
          :rows="4"
          placeholder="请输入自我介绍"
          v-model="form.intr"
          resize="none"
          style="width:700px;"
        >
        </el-input>
      </el-form-item>
      <el-form-item>
        <el-button class="btn-submit" type="primary" @click="onSubmit('form1')">提交</el-button>
        <el-button class="btn-cancel" @click="onReset('form1')">取消</el-button>
      </el-form-item>
    </el-form>
    <div v-else class="success">
      <div>录入成功</div>
      <ul>
        <li><label>ID:</label><span>{{form.id}}</span></li>
        <li><label>用户名:</label><span>{{form.uname}}</span></li>
        <li><label>姓名:</label><span>{{form.name}}</span></li>
        <li><label>性别:</label><span>{{form.sex | sexFilter}}</span></li>
        <li><label>出生日期:</label><span>{{form.birth | dateFilter}}</span></li>
        <li><label>籍贯:</label><span>{{form.jg}}</span></li>
        <li><label>兴趣爱好:</label><span>{{xqah||'无'}}</span></li>
        <li><label>自我介绍:</label><span>{{form.intr||'无'}}</span></li>
        <li style="margin-top:52px"><el-button class="btn-back" type="primary" @click="back('form1')">返回</el-button></li>
      </ul>
    </div>
  </div>
</template>
<script>
import inters from "../assets/js/apis/inters";
import cities from "../assets/js/apis/cities";
import users from "../assets/js/apis/users";

export default {
  data() {
    var checkName = (rule, value, callback) => {
      if(value.trim()==""){
        return callback(new Error("用户名不能为空"));
      }else{
        users.valiName(this.form.uname).then(result=>{
          if(result.data.ok==1){
            callback();
          }else{
            callback(new Error("用户名已存在，请更换新用户名"))
          }
        })
      }
    };
    var checkBirth = (rule, value, callback) => {
      if(value==null){
        return callback(new Error("出生日期不能为空"));
      }else{
        if(new Date().getTime()-value<18*365*24*3600*1000){
          callback(new Error("年龄不能小于18岁"))
        }else{
          callback();
        }
      }
    };
    var checkRepwd = (rule, value, callback) => {
      if(value==null){
        return callback(new Error("确认密码不能为空"));
      }else{
        if(this.form.pwd!==value){
          callback(new Error("两次输入的密码不一致!"))
        }else{
          callback();
        }
      }
    };
    return {
      form: {
        id:"",
        uname:"",
        name: "",
        pwd:"",
        repwd:"",
        sex: 1,
        birth: new Date().getTime() - 18 * 365 * 24 * 3600 * 1000,

        pid: "",
        cid: "",
        rid: "",
        iids: [],
        intr: "",
      },
      rules: {
        uname: [
          { required: true, validator: checkName, trigger: 'blur' }
        ],
        name:[
          { required: true, message: '姓名不能为空', trigger: 'blur'}
        ],
        pwd:[
          { required: true, message: '密码不能为空', trigger: 'blur'}
        ],
        repwd:[
          { required: true, validator: checkRepwd, trigger:'blur'}
        ],
        birth:[
          { validator: checkBirth, trigger: 'blur' }
        ],
        rid:[
          { required: true, message: '区/县不能为空', trigger: 'blur'}
        ]
      },
      provs: [],
      cities: [],
      inters: [],
      regions: []
    };
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          users.addUser(this.form).then(result=>{
            this.form={...this.form,...result.data};
            console.log(this.form);
          });
        } else {
          return false;
        }
      });
    },
    onReset(formName){
      this.$refs[formName].resetFields();
    },
    getCitiesByPid() {
      cities.getCities(this.form.pid).then((result) => {
        this.cities = result.data;
        this.form.cid="";
      });
    },
    getRegionsByCid() {
      cities.getRegions(this.form.cid).then((result) => {
        this.regions = result.data;
        this.form.rid="";
      });
    },
    back(){
      history.go(0);
    }
  },
  mounted() {
    inters.getInters().then((result) => {
      this.inters = result.data;
    });
    cities.getProvs().then((result) => {
      this.provs = result.data;
    });
  },
  computed:{
    xqah(){
      console.log(this.inters);
      console.log(this.form.iids);
      var arr=this.inters.filter(
        item=>this.form.iids.indexOf(item.id.toString())!=-1
      );
      console.log(arr);
      arr=arr.map(item=>item.interest)
      console.log(arr);
      return arr.join(",");
    }
  }
};
</script>
<style>
.btn-submit{
  width:120px;
  height: 38px;
  background: #146DFF;
  border-radius: 4px;
  color:#fff;
}
.btn-cancel{
  width:120px;
  margin-left:0;
  color:#146DFF;
}
.add-user2>.success{
  text-align:center;
}
.add-user2>.success>div{
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  line-height: 48px;
  margin-bottom:39px;
}
.add-user2>.success>ul>li{
  margin-bottom:39px;
}
.add-user2>.success>ul>li>label{
  width: 78px;
  font-size: 18px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  line-height: 48px;
  text-align:right;
  display:inline-block;
}
.add-user2>.success>ul>li>span{
  display:inline-block;
  margin-left:19px;
  width:200px;
  text-align:left;
}
</style>