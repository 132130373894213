import axios from './network';

export default {
  getProvs(){
    return axios.get("/cities/provs")
  },
  getCities(pid){
    return axios.get("/cities/cities",{
      params:{
        pid
      }
    })
  },
  getRegions(cid){
    return axios.get("/cities/regions",{
      params:{
        cid
      }
    })
  }
}